import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import React, { Suspense } from 'react';
import "@fortawesome/fontawesome-free/css/all.min.css";

  
const Login = React.lazy(() => import('./pages/auth/Login')); 
const ForgotPassword = React.lazy(() => import('./pages/auth/ForgotPassword.js')); 
const ResetPassword = React.lazy(() => import('./pages/auth/ResetPassword.js')); 
const Suppliers = React.lazy(() => import('./pages/Suppliers')); 
const AuthorizedInner = React.lazy(() => import('./component/AuthorizedInner')); 
const Location = React.lazy(() => import('./pages/Location')); 
const Product = React.lazy(() => import('./pages/Product')); 
const Part = React.lazy(() => import('./pages/Part.js')); 
const Purchase = React.lazy(() => import('./pages/inwardStock/Purchase.js')); 
const Challan = React.lazy(() => import('./pages/outwardStock/Challan.js')); 
const ProductOrPartSearch = React.lazy(() => import('./pages/search/ProductOrPartSearch.js')); 
const StockReport = React.lazy(() => import('./pages/report/StockReport.js')); 
const SalesReport = React.lazy(() => import('./pages/report/SalesReport.js')); 
const PurchaseReport = React.lazy(() => import('./pages/report/PurchaseReport.js')); 
const PurchaseCostReport = React.lazy(() => import('./pages/report/PurchaseCostReport.js')); 
const LaserReport = React.lazy(() => import('./pages/report/LaserReport.js'));
const CapitalPurchaseReport = React.lazy(() => import('./pages/report/CapitalPurchaseReport.js'));

function App() {
  const isUserLoggedIn = !!localStorage.getItem("token");
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
        <Route
            path="/"
            element={
              isUserLoggedIn ? (
                <Navigate to="/supplier" replace />
              ) : (
                <Login />
              )
            }
          />
         
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword/>} />
          <Route path="/reset-password" element={<ResetPassword/>} />
          <Route path="/supplier" element={<AuthorizedInner cmp={Suppliers} />} />
          <Route path="/location" element={<AuthorizedInner cmp={Location} />} />
          <Route path="/product" element={<AuthorizedInner cmp={Product} />} />
          <Route path="/part" element={<AuthorizedInner cmp={Part} />} />
          <Route path="/purchase" element={<AuthorizedInner cmp={Purchase} />} />
          <Route path="/challan" element={<AuthorizedInner cmp={Challan} />} />
          <Route path="/search" element={<AuthorizedInner cmp={ProductOrPartSearch} />} />
          <Route path="/stock-report" element={<AuthorizedInner cmp={StockReport} />} />
          <Route path="/sales-report" element={<AuthorizedInner cmp={SalesReport} />} />
          <Route path="/purchase-report" element={<AuthorizedInner cmp={PurchaseReport} />} />
          <Route path="/purchase-cost-report" element={<AuthorizedInner cmp={PurchaseCostReport} />} />
          <Route path="/laser-report" element={<AuthorizedInner cmp={LaserReport} />} />
          <Route path='/capital-purchase-report' element={<AuthorizedInner cmp={CapitalPurchaseReport} />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App; // Ensure this is correct
